import { Play as PlayIcon } from '@iheartradio/web.accomplice/icons';
import { PlayButton } from '@iheartradio/web.accomplice/player';
import { Playback } from '@iheartradio/web.playback';

import { useUser } from '~app/contexts/user';
import { isPremiumUser } from '~app/utilities/user';

import { type OptionalPlayProps, PlayControl } from './play';
import {
  type UseTopSongsPlayProps,
  useTopSongsPlay,
} from './use-top-songs-play';

export type TopSongsPlayProps = UseTopSongsPlayProps & OptionalPlayProps;

export function TopSongsPlay({
  color = 'white',
  size = 40,
  seed,
  ...props
}: TopSongsPlayProps) {
  const isPremium = isPremiumUser(useUser());
  const play = useTopSongsPlay(props);

  return isPremium ?
      <PlayControl
        color={color}
        seed={seed}
        size={size}
        type={Playback.StationType.TopSongs}
        {...props}
      />
    : <PlayButton color={color} onPress={play.doPlay}>
        <PlayIcon size={size} />
      </PlayButton>;
}
