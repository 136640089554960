import { Playback } from '@iheartradio/web.playback';

import { type OptionalPlayProps, PlayControl } from './play';
import type { UseLivePlayProps } from './use-live-play';

export type LivePlayProps = UseLivePlayProps & OptionalPlayProps;

export function LivePlay({ color, size = 40, ...props }: LivePlayProps) {
  return (
    <PlayControl
      color={color ?? { dark: 'white', light: 'gray' }}
      size={size}
      type={Playback.StationType.Live}
      {...props}
    />
  );
}
