import { Playback } from '@iheartradio/web.playback';

import { type OptionalPlayProps, PlayControl } from './play';
import type { UsePodcastPlayProps } from './use-podcast-play';

export type PodcastPlayProps = UsePodcastPlayProps & OptionalPlayProps;

export function PodcastPlay({
  color = 'white',
  size = 40,
  ...rest
}: PodcastPlayProps) {
  const props = {
    ...rest,
  };

  return (
    <PlayControl
      color={color}
      size={size}
      type={Playback.StationType.Podcast}
      {...props}
    />
  );
}
