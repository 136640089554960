import { vars } from '../../theme-contract.css.js';
import type { NotificationAction } from '../notification/notification.js';
import type { AddToastProps } from './utils.js';

export function UpgradeCTANotification({
  size,
  path,
  text = 'Want to listen to anything, anytime?',
}: {
  size: NotificationAction['size'];
  path: string;
  text?: string;
}): AddToastProps {
  return {
    kind: 'info',
    text,
    actions: [
      {
        color: 'gray',
        kind: 'tertiary',
        textColor: vars.color.gray600,
        size,
        content: 'Listen with iHeart All Access',
        href: path,
        target: '_blank',
      },
    ],
  };
}

export function AuthenticateCTANotification({
  trigger,
  text,
  size,
  paths,
  navigate,
  onAnalyticsRegGateOpen,
  onAnalyticsRegGateExit,
}: {
  trigger: string;
  text: string;
  size: NotificationAction['size'];
  paths: [string, string];
  navigate: (trigger: string) => void;
  onAnalyticsRegGateOpen: (trigger: string) => void;
  onAnalyticsRegGateExit: (trigger: string) => void;
}): AddToastProps {
  return {
    kind: 'info',
    onDismiss: () => onAnalyticsRegGateExit(trigger),
    text,
    actions: [
      {
        kind: 'tertiary',
        color: 'gray',
        textColor: vars.color.gray600,
        content: 'Log in',
        size,
        onPress: () => {
          onAnalyticsRegGateOpen(trigger);
          navigate(paths[0]);
        },
      },
      {
        kind: 'tertiary',
        color: 'gray',
        textColor: vars.color.gray600,
        content: 'Sign up',
        size,
        onPress: () => {
          onAnalyticsRegGateOpen(trigger);
          navigate(paths[1]);
        },
      },
    ],
  };
}

export function LyricsNotification({
  size,
}: {
  size: NotificationAction['size'];
}): AddToastProps {
  return {
    kind: 'info',
    text: 'Lyrics not available',
    actions: [
      {
        kind: 'tertiary',
        color: 'gray',
        textColor: vars.color.gray600,
        content: 'Cancel',
        shouldDismiss: true,
        size,
      },
      {
        kind: 'tertiary',
        color: 'gray',
        textColor: vars.color.gray600,
        content: 'Request Lyrics',
        size,
        onPress: () => {
          // pending
        },
      },
    ],
  };
}
