import type { ButtonProps } from '@iheartradio/web.accomplice/button';
import { Play as PlayIcon } from '@iheartradio/web.accomplice/icons';
import { PlayButton } from '@iheartradio/web.accomplice/player';
import { Playback } from '@iheartradio/web.playback';

import { useUser } from '~app/contexts/user';
import { isPremiumUser } from '~app/utilities/user';

import { type OptionalPlayProps, PlayControl } from './play';
import { type UseAlbumPlayProps, useAlbumPlay } from './use-album-play';

export type AlbumPlayProps = Omit<UseAlbumPlayProps, 'type' | 'targeting'> &
  OptionalPlayProps & { css?: ButtonProps['css'] };

export function AlbumPlay({
  css,
  color = 'white',
  size = 40,
  seed,
  ...props
}: AlbumPlayProps) {
  const isPremium = isPremiumUser(useUser());
  const play = useAlbumPlay(props);

  return isPremium ?
      <PlayControl
        {...props}
        color={color}
        css={css}
        seed={seed}
        size={size}
        type={Playback.StationType.Album}
      />
    : <PlayButton color={color} css={css} onPress={play.doPlay}>
        <PlayIcon size={size} />
      </PlayButton>;
}
