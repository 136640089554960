import { PlayButton } from '@iheartradio/web.accomplice/player';
import { Playback } from '@iheartradio/web.playback';

import { useUser } from '~app/contexts/user';
import { isPremiumUser } from '~app/utilities/user';

import { type OptionalPlayProps, PlayControl } from './play';
import {
  type UsePlaylistPlayProps,
  usePlaylistPlay,
} from './use-playlist-play';

export type PlaylistPlayProps = (Omit<UsePlaylistPlayProps, 'type'> & {
  type?: Playback.StationType.Playlist | Playback.StationType.PlaylistRadio;
  isDisabled?: boolean;
}) &
  OptionalPlayProps;

export function PlaylistPlay({
  color = 'white',
  type = Playback.StationType.Playlist,
  isDisabled = false,
  size = 40,
  ...props
}: PlaylistPlayProps) {
  const userIsPremium = isPremiumUser(useUser());

  const play = usePlaylistPlay({ ...props, type });

  if (type === Playback.StationType.Playlist) {
    return userIsPremium ?
        <PlayControl
          color={color}
          isDisabled={isDisabled}
          seed={(props as Playback.PlaylistStation)?.seed}
          type={Playback.StationType.Playlist}
          {...props}
        />
      : <PlayButton
          color={color}
          onPress={() => {
            play.doPlay();
          }}
        />;
  }

  return userIsPremium ?
      <PlayControl
        color={color}
        isDisabled={isDisabled}
        seed={(props as Playback.PlaylistStation)?.seed}
        size={size}
        type={Playback.StationType.Playlist}
        {...props}
      />
      // if playlist profile hero... return play button with shuffle = true; else ...
    : <PlayControl
        color={color}
        isDisabled={isDisabled}
        shuffle={true}
        size={size}
        type={Playback.StationType.PlaylistRadio}
        {...props}
      />;
}
