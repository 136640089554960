import { Playback } from '@iheartradio/web.playback';
import { isNotBlank } from '@iheartradio/web.utilities';
import { useCallback } from 'react';

import { AdsTargetingState, useTargetingReady } from '~app/contexts/ads';
import { playback } from '~app/playback/playback';

import { useIsCurrentStation } from './use-is-current-station';

export type UsePlayProps = Omit<Playback.Station, 'targeting'> & {
  isControlSet?: boolean;
  isScanStation?: boolean;
};

export type DoPlayProps = {
  toggleScan?: boolean;
};

export function usePlay(props: UsePlayProps) {
  const isCurrent = useIsCurrentStation(props);
  const checkReadyToLoad = useTargetingReady();

  const doLoadAndPlay = useCallback(
    async (player: Playback.Player<Playback.Station>, props: UsePlayProps) => {
      await player.load({
        ...props,
        targeting: AdsTargetingState.get('targetingParams'),
      });
      await player.play();
    },
    [],
  );

  const player = playback.usePlayer<Playback.Station>();
  const { status: adsStatus } = playback.useAds();
  const { index, isScanning, queue, status, station } = playback.useState();

  const adBreak = ![
    Playback.AdPlayerStatus.Done,
    Playback.AdPlayerStatus.Idle,
    Playback.AdPlayerStatus.Streaming, // Don't disable player buttons if we're listening to a live stream ad
  ].includes(adsStatus);

  const stoppable = queue[index]?.type === Playback.QueueItemType.Stream;

  return {
    async doPlay(doPlayProps?: DoPlayProps) {
      const { toggleScan = false } = doPlayProps ?? {};
      if (isNotBlank(props) && !isCurrent) {
        if (checkReadyToLoad()) {
          doLoadAndPlay(player, props);
        } else {
          (function doCheck() {
            globalThis?.window?.setTimeout(() => {
              if (checkReadyToLoad()) {
                doLoadAndPlay(player, props);
              } else {
                doCheck();
              }
            }, 100);
          })();
        }
      } else {
        if ([Playback.Status.Idle, Playback.Status.Paused].includes(status)) {
          const resolverState = player._get();
          // If the user presses "Scan Stations", we always want to reset the iteration counter to zero
          if (resolverState) {
            resolverState.set('iterations', 0);
          }
          player.play();
        } else if (station.type === Playback.StationType.Scan) {
          // If the user presses "Scan Stations" (vs. the stop button in the player bar), we want
          // to just stop the scan, not playback
          if (toggleScan) {
            player.setScanning({ isScanning: !isScanning });
          } else if (!toggleScan) {
            player.stop();
          }
        } else {
          const method = stoppable ? player.stop : player.pause;
          method();
        }
      }
    },
    buffering:
      (status === Playback.Status.Buffering && isCurrent && !adBreak) ||
      adsStatus === Playback.AdPlayerStatus.Buffering,
    disabled: !isCurrent && adBreak,
    isCurrent,
    playing:
      (status === Playback.Status.Buffering ||
        status === Playback.Status.Playing) &&
      isCurrent,
    stoppable: stoppable && !adBreak,
    tooltip:
      isCurrent ?
        {
          [Playback.Status.Buffering]: 'Buffering',
          [Playback.Status.Idle]: 'Play',
          [Playback.Status.Paused]: adBreak ? 'Resume Ad' : 'Play',
          [Playback.Status.Playing]:
            stoppable ?
              adBreak ? 'Pause Ad'
              : 'Stop'
            : 'Pause',
          // This status only appears for a split second when restarting content, and will never be visible in the tooltip
          // Needed to be present here to satisfy TS
          [Playback.Status.Restart]: 'Play',
        }[status]
      : 'Play',
    adBreak,
  } as const;
}
