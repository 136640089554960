import { Playback } from '@iheartradio/web.playback';
import { useCallback } from 'react';

import { AdsTargetingState, useTargetingReady } from '~app/contexts/ads';
import { useUser } from '~app/contexts/user';
import { isPremiumUser } from '~app/utilities/user';

import { playback } from '../../playback';
import { usePlay } from './use-play';

export type UsePlaylistPlayProps = Omit<
  Playback.PlaylistRadioStation | Playback.PlaylistStation,
  'targeting'
>;

export function usePlaylistPlay(props: UsePlaylistPlayProps) {
  const isPremium = isPremiumUser(useUser());
  const player = playback.usePlayer();
  const checkReadyToLoad = useTargetingReady();

  const doLoadAndPlay = useCallback(async () => {
    await player.load({
      ...props,
      type: Playback.StationType.PlaylistRadio,
      targeting: AdsTargetingState.get('targetingParams'),
    });
    await player.play();
  }, [player, props]);

  const play = usePlay({
    ...props,
    type:
      isPremium ?
        Playback.StationType.Playlist
      : Playback.StationType.PlaylistRadio,
  });

  async function doPlay() {
    if (isPremium) {
      play.doPlay();
      return;
    }

    if (checkReadyToLoad()) {
      doLoadAndPlay();
    } else {
      (function doCheck() {
        globalThis.window.setTimeout(() => {
          if (checkReadyToLoad()) {
            doLoadAndPlay();
          } else {
            doCheck();
          }
        }, 100);
      })();
    }
  }

  return { ...play, doPlay } as const;
}
