import {
  addToast,
  AuthenticateCTANotification,
} from '@iheartradio/web.accomplice/toast';
import { Playback } from '@iheartradio/web.playback';
import { useNavigate } from '@remix-run/react';
import { useCallback } from 'react';
import { $path } from 'remix-routes';

import { useRegGateEvent } from '~app/analytics/use-reg-gate';
import { useUser } from '~app/contexts/user';
import { REG_GATE_TRIGGER_TYPES, Routes } from '~app/utilities/constants';

import { usePlay } from './use-play';

export type UseFavoritesPlayProps = Omit<
  Playback.FavoritesStation,
  'type' | 'targeting' | 'seed'
>;

export function useFavoritesPlay(props: UseFavoritesPlayProps) {
  const { isAnonymous } = useUser();

  const navigate = useNavigate();
  const { onAnalyticsRegGateOpen, onAnalyticsRegGateExit } = useRegGateEvent();

  const play = usePlay({ ...props, type: Playback.StationType.Favorites });

  const doPlay = useCallback(async () => {
    if (!isAnonymous) {
      play.doPlay();
      return;
    }
    addToast(
      AuthenticateCTANotification({
        trigger: REG_GATE_TRIGGER_TYPES.MADE_FOR_YOU,
        text: 'Log in to save content and enjoy your personalized playlists',
        size: { xsmall: 'small', medium: 'large' },
        paths: [$path(Routes.Login, { action: 'auth' }), $path(Routes.SignUp)],
        navigate,
        onAnalyticsRegGateOpen,
        onAnalyticsRegGateExit,
      }),
    );
  }, [
    isAnonymous,
    navigate,
    onAnalyticsRegGateExit,
    onAnalyticsRegGateOpen,
    play,
  ]);

  return { ...play, doPlay } as const;
}
