import type { ButtonProps } from '@iheartradio/web.accomplice/button';
import { Button } from '@iheartradio/web.accomplice/button';
import { Flex } from '@iheartradio/web.accomplice/flex';
import type { IconProps } from '@iheartradio/web.accomplice/icon';
import * as Icons from '@iheartradio/web.accomplice/icons';
import { PlayButton } from '@iheartradio/web.accomplice/player';
import {
  Popover,
  PopoverText,
  PopoverTitle,
} from '@iheartradio/web.accomplice/popover';
import { type ComponentProps, useState } from 'react';

import { useConfig } from '~app/contexts/config';

import type { UsePlayProps } from './use-play';
import { usePlay } from './use-play';

export interface OptionalPlayProps {
  color?: ComponentProps<typeof Button>['color'];
  size?: IconProps['size'];
  isDisabled?: boolean;
  shuffle?: boolean;
}

export type PlayControlProps = UsePlayProps &
  OptionalPlayProps & { css?: ButtonProps['css'] };

export function PlayControl({
  color,
  css,
  size = 40,
  isDisabled,
  shuffle = false,
  ...props
}: PlayControlProps) {
  const {
    adBreak,
    buffering,
    disabled,
    isCurrent,
    playing,
    doPlay,
    stoppable,
    tooltip,
  } = usePlay(props);
  const config = useConfig();
  const [isOpen, setIsOpen] = useState(false);

  const showPopover = adBreak && !isCurrent;

  if (showPopover) {
    return (
      <Popover
        isOpen={!showPopover ? false : isOpen}
        name="ad-break-upsell"
        onOpenChange={isOpen => {
          setIsOpen(isOpen);
        }}
        placement="bottom"
        trigger={
          <PlayButton
            data-test="player-play-button-with-ad-break-popover"
            isDisabled={isDisabled ?? false}
            isPending={buffering === true && isCurrent}
            shuffle={shuffle}
            tooltip={tooltip}
          >
            <Icons.Play size={size} />
          </PlayButton>
        }
      >
        <Flex direction="column" gap="$16">
          <PopoverTitle>Start listening after the ad</PopoverTitle>
          <PopoverText>Want to listen to anything, anytime?</PopoverText>
          <Button
            color="red"
            css={{
              fontSize: '$14',
              ...css,
            }}
            href={`${config.urls.account}/subscribe`}
            kind="primary"
            size="large"
            target="_blank"
          >
            Listen with iHeart All Access
          </Button>
        </Flex>
      </Popover>
    );
  }

  return (
    <PlayButton
      color={color}
      css={css}
      data-test="player-play-button"
      isDisabled={isDisabled ?? disabled}
      isPending={buffering}
      onPress={() => doPlay()}
      shuffle={shuffle}
      tooltip={tooltip}
    >
      {!playing && <Icons.Play size={size} />}
      {(!stoppable && playing) || (playing && adBreak) ?
        <Icons.Pause size={size} />
      : null}
      {stoppable && playing && !adBreak ?
        <Icons.Stop size={size} />
      : null}
    </PlayButton>
  );
}
