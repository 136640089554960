import * as Analytics from '@iheartradio/web.analytics';
import { createWebStorage } from '@iheartradio/web.utilities/create-storage';
import { useCallback } from 'react';

import { useAnalytics } from './create-analytics';

export function useRegGateEvent() {
  const analytics = useAnalytics();

  const regGateState = createWebStorage({
    seed: {
      trigger: '',
    },
    prefix: 'reg_gate.',
    type: 'session',
  });

  const onAnalyticsRegGateOpen = useCallback(
    (
      trigger: Analytics.Analytics.RegGateOpen['regGate']['trigger'],
      type?: Analytics.Analytics.RegGateOpen['regGate']['type'],
    ) => {
      analytics.track({
        type: Analytics.eventType.enum.RegGateOpen,
        data: {
          regGate: {
            trigger,
            type: type ?? 'hard_gate',
          },
        },
      });
      regGateState.set('trigger', trigger);
    },
    [analytics, regGateState],
  );

  const onAnalyticsRegGateExit = useCallback(
    (
      trigger: Analytics.Analytics.RegGateExit['regGate']['trigger'],
      exitType?: Analytics.Analytics.RegGateExit['regGate']['exitType'],
    ) => {
      analytics.track({
        type: Analytics.eventType.enum.RegGateExit,
        data: {
          regGate: {
            trigger,
            type: 'hard_gate',
            exitType: exitType ?? 'dismiss',
          },
        },
      });
      regGateState.set('trigger', '');
    },
    [analytics, regGateState],
  );

  return { onAnalyticsRegGateOpen, onAnalyticsRegGateExit, regGateState };
}
